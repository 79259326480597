import { modalsVar } from '@/graphql/cache';
import checkAllowClose from '@/modals/utils/checkAllowClose';

export default async function closeModal({ id, isCancelable = false }) {
  if (!isCancelable || (await checkAllowClose(id))) {
    const modals = modalsVar();
    return modalsVar(modals.filter((m) => m.id !== id));
  }

  return modalsVar();
}
