import updateModal from '@/modals/utils/updateModal';

function getConfirmConfig(customConfig) {
  return {
    omitInput: true,
    confirmCTA: 'Discard',
    description: 'You have unsaved changes. Are you sure you want to discard them?',
    ...customConfig,
  };
}

export default function setCheckBeforeClose(id, value, config) {
  return updateModal({
    id,
    data: { checkBeforeClose: value, confirmConfig: getConfirmConfig(config) },
  });
}
