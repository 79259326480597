import { modalsVar } from '@/graphql/cache';

export default function updateModal({ id, props, data }) {
  const modals = modalsVar();
  const currentModal = modals.find((m) => m.id === id);

  return modalsVar([
    ...modals.filter((m) => m.id !== id),
    {
      ...currentModal,
      props: {
        ...currentModal.props,
        ...props,
      },
      data: { ...currentModal.data, ...data },
    },
  ]);
}
