import toggleModal from '@/modals/utils/toggleModal';
import showModal from '@/modals/utils/showModal';
import closeModal from '@/modals/utils/closeModal';
import updateModal from '@/modals/utils/updateModal';
import setCheckBeforeClose from '@/modals/utils/setCheckBeforeClose';

/**
 * @param {string} id
 * @returns {{ toggle: Function, show: Function, close: Function, update: Function, preventClose: Function, allowClose: Function }}
 */
export default function useModal(id) {
  return {
    toggle: (modal) => toggleModal({ id, ...modal }),
    show: (modal) => showModal({ id, ...modal }),
    // close will close the modal without checking if it can be closed.
    close: () => closeModal({ id }),
    // userClose will check if the modal can be closed before closing it.
    userClose: () => closeModal({ id, isCancelable: true }),
    update: (modal) => updateModal({ id, ...modal }),
    preventClose: () => updateModal({ id, data: { preventClose: true } }),
    allowClose: () => updateModal({ id, data: { preventClose: false } }),
    setCheckBeforeClose: (value, config) => setCheckBeforeClose(id, value, config),
  };
}
