import { modalsVar } from '@/graphql/cache';
import confirm from '@/storychief/utils/confirm';

export default async function checkAllowClose(id) {
  const modal = modalsVar().find((m) => m.id === id) || null;
  const preventClose = modal?.data?.preventClose || false;
  const checkBeforeClose = modal?.data?.checkBeforeClose || false;

  if (preventClose) {
    return false;
  }

  if (checkBeforeClose && !(await confirm(modal.data.confirmConfig))) {
    return false;
  }

  return true;
}
